* {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

body {
  background-color: #f2f3f4;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: white;
}

#container {
  padding: 30px 20px;
  max-width: 1000px;
  margin: 0 auto;
}

main {
  width: 90%;
  padding: 30px 20px;
  background: rgb(75, 184, 195);
  background: linear-gradient(0deg, rgba(75, 184, 195, 1) 15%, rgba(151, 200, 61, 1) 100%);
  margin: 50px;
  border-radius: 20px;
  -webkit-box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.3);
}

@media (min-width: 400px) {
  main {
    padding: 30px;
  }
}

@media (min-width: 820px) {
  main {
    width: 800px;
    padding: 40px;
  }
}

header {
  background-color: #212240;
  height: fit-content;
  display: block;
  width: 100%;
}

.xapling-logo {
  width: 80px;
  height: 80px;
  margin-left: 20px;
  padding: 10px;
}

h1 {
  margin: 30px 0 50px;
  color: #020381;
  text-align: center;
}

/* LOGIN BUTTON */
.logoutButton,
.loginButton {
  position: absolute;
  right: 30px;
  top: 20px;
  padding: 10px 30px;
  width: fit-content;
  justify-content: center;
  cursor: pointer;
  display: flex;
  background-color: rgba(151, 200, 61, 1);
  border-radius: 35px;
  color: #1e266d;
  font-weight: 600;
  transition: 0.3s;
}

.logoutButton:hover,
.loginButton:hover {
  background-color: rgba(75, 184, 195, 1);
  transition: 0.2s;
}

.logoutIcon > i,
.loginIcon > i {
  cursor: pointer;
  font-size: 20px;
  padding-right: 10px;
}

.startseite-button {
  margin-bottom: 20px;
  padding: 30px 20px;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 400ms, box-shadow 400ms;
  text-decoration: none;
}

.startseite-button:hover {
  transition: transform 200ms, box-shadow 200ms;
  transform: translateY(-15px);
  -webkit-box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.2);
}

.startseite-button-text {
  margin-top: 20px;
  line-height: 1.3;
  font-weight: 600;
  color: #1e266d;
}

footer:before,
footer:after {
  content: '';
  position: fixed;
  bottom: 0;
  display: inline-block;
  height: 10px;
  width: 50%;
}

footer:before {
  background-color: rgba(151, 200, 61, 1);
  left: 0;
}

footer:after {
  background-color: rgba(75, 184, 195, 1);
  right: 0;
}
